/*eslint no-return-assign:0*/
import _ from 'lodash';
import * as modelUtils from './modelUtils';
import {HooksManager} from './RMIHooks/hooksManager';
import repeaterUtils from './repeaterUtils/repeaterUtils';

const DATA = 'data';
const FULL_DATA = 'fullData';
const DESIGN = 'design';
const STATE = 'state';
const TYPE = 'type';
const PROPS = 'props';
const PARENT = 'parent';
const EVENTS = 'events';
const LAYOUT = 'layout';
const BEHAVIOR = 'behavior';
const PUBLIC_API = 'publicAPI';
const IS_DISPLAYED = 'isDisplayed';
const STYLE = 'style';
const ID = 'id';
const CHILDREN = 'children';
const DISPLAYED_ONLY_COMPONENTS = 'displayedOnlyComponents';
const DISPLAYED_ROOT = 'displayedRoot';
const GLOBAL_SCOPE = 'GLOBAL_SCOPE';
const COMPONENT_SCOPE = 'COMPONENT_SCOPE';

export class RemoteModelInterface {
  constructor(modelJson, onUpdateCallback, options) {
    this._model = modelJson || {
      components: {},
      connections: {},
      componentsScopes: {},
      navigation: {},
      siteStructure: {},
      pageData: {},
      eventHandlers: {},
      EventTypes: {},
      sessionInfo: {},
      appsData: {},
      siteMemberData: {uid: null, permissions: null, onUserLogin: []},
      scopesHierarchy: [{type: GLOBAL_SCOPE, id: GLOBAL_SCOPE, additionalData: {}}]
    };
    this._onUpdateCallback = onUpdateCallback;

    const optionsHookManager = _.get(options, '_hooksManager');
    const optionsTransaction = _.get(options, '_transaction');
    this._hooksManager = optionsHookManager ? optionsHookManager : new HooksManager();
    this._transaction = optionsTransaction ? optionsTransaction : {_onTransaction: false, _transactionData: {}}
  }

  addComponent(compId, compDescriptor) {
    const comp = generateDefaultCompStructure();
    for (const key in comp) { //eslint-disable-line guard-for-in
      comp[key] = compDescriptor[key] || comp[key];
    }
    comp[PARENT] = compDescriptor[PARENT];
    comp[TYPE] = compDescriptor[TYPE];
    comp[ID] = compDescriptor[ID];
    comp[DISPLAYED_ROOT] = compDescriptor[DISPLAYED_ROOT];
    this._model.components[compId] = comp;
    this._model.componentsScopes[compId] = GLOBAL_SCOPE;
  }

  addNavigation(navigationData) {
    this._model.navigation = navigationData;
  }

  addSiteStructure(siteStructureData) {
    this._model.siteStructure = siteStructureData;
  }

  addPageData(pageData) {
    this._model.pageData = pageData;
  }

  addConnections(connectionsModel) {
    this._model.connections = connectionsModel;
  }

  resetComponentsScope() {
    for (const compId in this._model.components) { //eslint-disable-line guard-for-in
      const displayedOnlyCompIds = this._model.components[compId].displayedOnlyComponents;

      displayedOnlyCompIds.forEach(displayedOnlyCompId => this._model.componentsScopes[displayedOnlyCompId] = null);
    }
  }

  addEventTypes(EventTypes) {
    this._model.EventTypes = EventTypes;
  }

  addSiteMemberData(siteMemberData) {
    const shouldTriggerOnLoginCallback = siteMemberData.uid && siteMemberData.uid !== this._model.siteMemberData.uid;
    _.assign(this._model.siteMemberData, siteMemberData);
    if (shouldTriggerOnLoginCallback) {
      const onUserLoginCallbacks = this._model.siteMemberData.onUserLogin;
      _.forEach(onUserLoginCallbacks, callback => {
        callback(siteMemberData);
      });

    }
  }

  addAppsData(appsData) {
    this._model.appsData = appsData
  }

  getAppsData() {
    return _.cloneDeep(this._model.appsData);
  }

  getAppData(appDefinitionId) {
    return _.cloneDeep(_.get(this._model.appsData, appDefinitionId));
  }

  addOnUserLoginCallback(callback) {
    this._model.siteMemberData.onUserLogin.push(callback);
  }

  addSessionInfoProp(addSessionInfoProp) {
    _.assign(this._model.sessionInfo, addSessionInfoProp);
  }

  //Getters

  getComp(compId) {
    return _.cloneDeep(_.get(this._model.components, compId));
  }

  getState(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, STATE]));
  }

  getData(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, DATA]));
  }

  getStyle(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, STYLE]));
  }

  getDesign(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, DESIGN]));
  }

  getType(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, TYPE]));
  }

  getProps(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, PROPS]));
  }

  getEvents(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, EVENTS]));
  }

  getLayout(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, LAYOUT]));
  }

  getId(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, ID]));
  }

  getEventTypes() {
    return _.cloneDeep(this._model.EventTypes);
  }

  getPublicAPI(compId) {
    const publicApi = _.get(this._model.components, [compId, PUBLIC_API]);
    if (_.isFunction(publicApi)) {
      return publicApi;
    }
    return _.cloneDeep(publicApi);
  }

  getCallbackById(callbackId, newFormat) {
    const eventHandler = this._model.eventHandlers[callbackId]; // TODO - backward compatibility - remove after SDK next release
    if (newFormat) {
      return eventHandler;
    }
    return _.get(eventHandler, 'callback');
  }

  getParent(compId) {
    return _.cloneDeep(_.get(this._model.components, [compId, PARENT]));
  }

  getChildren(parentId) {
    return _.clone(_.get(this._model.components, [parentId, CHILDREN]));
  }

  getSiteStructure() {
    return _.cloneDeep(this._model.siteStructure);
  }

  getNavigationInfo() {
    return _.cloneDeep(this._model.navigation);
  }

  getPageData() {
    return _.cloneDeep(this._model.pageData);
  }

  getFullDataWithOverrides(compId) {
    return _.defaults(this.getData(compId), getFullData.call(this, compId));
  }

  getSiteMemberData(appDefinitionId) {
    const siteMemberData = _.cloneDeep(this._model.siteMemberData);
    if (appDefinitionId) {
      const appData = _.get(this._model.appsData, appDefinitionId);
      if (appData) {
        _.assign(siteMemberData, _.pick(appData, ['instance', 'instanceId']))
      }
    }
    return siteMemberData;
  }

  getSessionInfo() {
    return _.cloneDeep(this._model.sessionInfo);
  }

  //Setters

  setDataWithoutUpdate(compId, partialData) {
    set.call(this, compId, DATA, partialData);
  }

  setData(compId, partialData) {
    set.call(this, compId, DATA, partialData, this._onUpdateCallback);
  }

  setStyle(compId, partialData, cb) {
    set.call(this, compId, STYLE, partialData, (cid, property, partial) => {
      this._onUpdateCallback.call(this, cid, property, partial, cb);
    });
  }

  setDesign(compId, partialData) {
    set.call(this, compId, DESIGN, partialData, this._onUpdateCallback);
  }

  setProps(compId, partialProps, cb) {
    set.call(this, compId, PROPS, partialProps, (cid, property, partial) => {
      this._onUpdateCallback.call(this, cid, property, partial, cb);
    });
  }

  setLayout(compId, partialLayout) {
    set.call(this, compId, LAYOUT, partialLayout, this._onUpdateCallback);
  }

  setPublicAPI(compId, api) {
    const comp = _.get(this._model.components, compId);
    if (comp) {
      comp[PUBLIC_API] = api;
    }
  }

  getCurrentScope() {
    return _.clone(_.get(this._model.scopesHierarchy, 0));
  }

  executeCompBehavior(compId, behaviorName, params, callback) {
    executeBehavior.call(this, 'comp', behaviorName, params, compId, callback);
  }

  executeAnimation(compId, animationName, params, callback) {
    executeBehavior.call(this, 'animation', animationName, params, compId, callback);
  }

  setUpdateCallback(onUpdateCallback) {
    this._onUpdateCallback = onUpdateCallback;
  }

  registerEvent(contextId, compId, eventType, controllerId, callback) {
    let callbackId;
    if (callback === undefined) { //TODO - backward compatibility - remove after SDK next release
      callback = controllerId;
      controllerId = undefined;
    }
    if (_.isFunction(callback)) {
      callbackId = guid();
      this._model.eventHandlers[callbackId] = {callback, contextId, controllerId};
    } else {
      callbackId = callback;
    }
    const eventDescriptor = {
      contextId,
      eventType,
      callbackId
    };
    const component = _.get(this._model, ['components', compId]);
    if (component !== undefined) {
      this._hooksManager.executeHooksAndUpdateValue(this, 'registerEvent', component.type, compId, [contextId, eventType, controllerId, callback]);
      component.events.push({eventType, callbackId});
      if (this._transaction._onTransaction) {
        addItemToTransactionQueue.call(this, compId, 'registerEvent', eventDescriptor);
        return;
      }
      if (_.isFunction(this._onUpdateCallback)) {
        this._onUpdateCallback(compId, 'registerEvent', eventDescriptor);
      }
    }
  }

  toJson() {
    return this._model;
  }

  //General

  getComponents() {
    const templateComponents = {};
    for (const compId in this._model.components) { //eslint-disable-line guard-for-in
      if (!modelUtils.isTemplateComp(this._model, compId)) {
        templateComponents[compId] = this.getComp(compId);
      }
    }

    return templateComponents;
  }

  getScopedRMI(controllerId) {
    const scopedRMI = new this.constructor(this._model, this._onUpdateCallback, {_hooksManager: this._hooksManager, _transaction: this._transaction});
    scopedRMI.getCompIdsFromRole = scopedRMI.getCompIdsFromRole.bind(scopedRMI, controllerId);
    scopedRMI.getCompIdsFromType = scopedRMI.getCompIdsFromType.bind(scopedRMI, controllerId);
    scopedRMI.getConfig = scopedRMI.getConfig.bind(scopedRMI, controllerId);

    return scopedRMI;
  }

  getNestedScopedRMI(model) {
    return new this.constructor(model || this._model, this._onUpdateCallback, {_hooksManager: this._hooksManager, _transaction: this._transaction});
  }

  getCompIdsFromType(controllerId, type, onlyNested) {
    const components = _.get(this._model.connections, controllerId);
    const compIds = _.flatMap(components, Object.keys);
    const comps = [];
    const acceptedScopes = getCurrentScopeId.call(this, onlyNested);

    compIds.forEach(function (compId) {
      const compType = this.getType(compId);
      if (compType === type && _.includes(acceptedScopes, _.get(this._model.componentsScopes, compId))) {
        comps.push(compId);
      }
    }, this);
    return comps;
  }

  getCompIdsFromRole(controllerId, role, onlyNested) {
    const connectionConfigs = _.get(this._model.connections, [controllerId, role]);
    const acceptedScopes = getCurrentScopeId.call(this, onlyNested);
    const compIds = connectionConfigs ? Object.keys(connectionConfigs) : [];
    return compIds.filter(compId => _.includes(acceptedScopes, _.get(this._model.componentsScopes, compId)));
  }

  getConfig(controllerId, compId, role, onlyNested) {
    const connectionConfigs = _.get(this._model.connections, [controllerId, role]);
    const acceptedScopes = getCurrentScopeId.call(this, onlyNested);

    return connectionConfigs && connectionConfigs.hasOwnProperty(compId) && _.includes(acceptedScopes, _.get(this._model.componentsScopes, compId)) ? connectionConfigs[compId] : {};
  }

  updateModel(modelUpdates) {
    for (const compId in modelUpdates) { //eslint-disable-line guard-for-in
      const defaultComp = generateDefaultCompStructure();
      const comp = _.get(this._model.components, compId);
      const compUpdates = modelUpdates[compId];
      if (!comp) {
        continue;
      }

      for (const keyToUpdate in compUpdates) {
        if (!_.has(comp, keyToUpdate)) {
          continue;
        }
        if (_.isObject(comp[keyToUpdate])) {
          if (compUpdates[keyToUpdate]) {
            _.assign(comp[keyToUpdate], compUpdates[keyToUpdate]);
          } else { // for modes change, when key should be initial to default value
            comp[keyToUpdate] = defaultComp[keyToUpdate];
          }
        } else {
          comp[keyToUpdate] = compUpdates[keyToUpdate];
        }
      }
    }
  }

  getScopedRMIByCompId(compId) {
    const itemContext = this.getRepeaterItemContext(compId);
    if (_.isEmpty(itemContext)) {
      return this;
    }

    const {displayedRoot, itemId} = itemContext;

    return this.getScopedRMIForRepeaterItem(displayedRoot, itemId);
  }

  getScopedRMIForRepeaterItem(repeaterId, itemId) {
    const model = _.clone(this._model);
    const templateCompIds = modelUtils.getAllCompsUnderRoot(model, repeaterId);
    const itemCompIds = templateCompIds.map(compId => repeaterUtils.structure.getUniqueDisplayedId(compId, itemId));

    model.scopesHierarchy = _.clone(model.scopesHierarchy);
    model.scopesHierarchy.unshift({type: COMPONENT_SCOPE, id: repeaterId, compId: repeaterId, additionalData: {itemId}});

    model.componentsScopes = _.clone(model.componentsScopes);
    templateCompIds.forEach(templateCompId => model.componentsScopes[templateCompId] = null);
    itemCompIds.forEach(itemCompId => model.componentsScopes[itemCompId] = repeaterId); //eslint-disable-line no-return-assign

    return this.getNestedScopedRMI(model);
  }

  getRepeaterItemContext(compId) {
    const displayedRoot = _.get(this._model.components, [compId, DISPLAYED_ROOT]);

    if (!displayedRoot) {
      return {};
    }

    const itemId = repeaterUtils.structure.getItemId(compId);
    return {displayedRoot, itemId}
  }

  startTransaction() {
    this._transaction._onTransaction = true;
  }

  endTransaction() {
    if (this._onUpdateCallback) {
      this._onUpdateCallback(null, 'executeBatch', this._transaction._transactionData);
    }
    this._transaction._onTransaction = false;
    this._transaction._transactionData = {};

  }

  setBatchData(newData) {
    _.forEach(newData, (dataByProperty, compId) => {
      _.forEach(dataByProperty, (partialData, propertyType) => {
        switch (propertyType) {
        case 'registerEvent':
          _.forEach(partialData, eventDescriptor => {
            this.registerEvent.call(this, eventDescriptor.contextId, compId, eventDescriptor.eventType, eventDescriptor.controllerId, eventDescriptor.callbackId)
          });
          break;
        default:
          const funcName = `set${_.startCase(propertyType)}`;
          this[funcName].call(this, compId, partialData);
          break;
        }

      });
    });
  }

  registerHook(hookName, compType, callback) {
    this._hooksManager.registerHook(hookName, compType, callback);
  }

  getHooks() {
    return this._hooksManager.HOOKS
  }
}

function getCurrentScopeId(onlyNested) {
  return onlyNested ? _.get(this._model.scopesHierarchy, '0.id') : _.map(this._model.scopesHierarchy, scopeHierarchy => _.get(scopeHierarchy, 'id'));
}

function generateDefaultCompStructure() {
  return {
    [PARENT]: null,
    [STATE]: {},
    [TYPE]: null,
    [DATA]: {},
    [FULL_DATA]: {},
    [DESIGN]: {},
    [PROPS]: {},
    [LAYOUT]: {},
    [EVENTS]: [],
    [IS_DISPLAYED]: false,
    [ID]: null,
    [PUBLIC_API]: {},
    [STYLE]: {},
    [CHILDREN]: [],

    [DISPLAYED_ONLY_COMPONENTS]: [],
    [DISPLAYED_ROOT]: null
  };
}

function getFullData(compId) {
  return _.cloneDeep(_.get(this._model.components, [compId, FULL_DATA]));
}

function executeBehavior(behaviorType, behaviorName, params, compId, callback) {
  const requiresDom = _.get(params, 'requiresDom', {compId});
  params = requiresDom ? params : _.omit(params, ['requiresDom']);

  const behavior = {
    type: behaviorType,
    name: behaviorName,
    targetId: compId,
    requiresDom,
    params
  };

  if (this._onUpdateCallback) {
    this._onUpdateCallback(compId, BEHAVIOR, behavior, callback);
  }
}

//Utility

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

function set(compId, property, partial, onUpdateCallback) {
  const comp = _.get(this._model, ['components', compId]);
  if (!comp) {
    return;
  }

  this._hooksManager.executeHooksAndUpdateValue(this, property, comp.type, compId, [partial, onUpdateCallback]);
  if (comp[IS_DISPLAYED]) {
    this._model.components[compId][property] = _.defaults({}, partial, comp[property]);
  }
  if (onUpdateCallback) {
    if (this._transaction._onTransaction) {
      addItemToTransactionQueue.call(this, compId, property, partial);
    } else {
      onUpdateCallback(compId, property, partial);
    }
  }
}

function addItemToTransactionQueue(compId, property, partial) {
  const transactionData = this._transaction._transactionData;
  if (!transactionData[compId]) {
    transactionData[compId] = {};
  }

  if (property === 'registerEvent') {
    if (!transactionData[compId][property]) {
      transactionData[compId][property] = [];
    }
    transactionData[compId][property].push(partial);
    return;
  }
  if (!transactionData[compId][property]) {
    transactionData[compId][property] = {};
  }
  _.assign(transactionData[compId][property], partial);
}

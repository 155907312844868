import _ from 'lodash';

const HOOKS = {
  all: 'all',
  data: 'data'
};

const ALL_COMPS_TYPE = '*';

export class HooksManager {
  constructor() {
    this._hooks = {};
  }

  /**
   * Validate that the hook name is correct
   * @param {String} hookName - hooks name
   */
  validateHookName(hookName) {
    if (!HOOKS[hookName]) {
      throw 'Invalid Hook Name ' + hookName;
    }
  }

  /**
   * Unregister all hooks.
   */
  unregisterAllHooks() {
    this._hooks = {}
  }

  /**
   * Unregister only hooks that are passed
   * @param {Array} hooksToRemove - array of hooks names
   */
  unregisterHooks(hooksToRemove) {
    _.forEach(hooksToRemove, hookName => delete this._hooks[hookName]);
  }

  /**
   * Execute all callbacks of the specified hook for the specified component. if registered to all will trigger this as well
   * @param {String} RMI
   * @param {String} hookName The name of the hook to execute (i.e HOOKS.DATA).
   * @param {String} compType The component type or none to  hooks that were registered for all component types.
   * @param {String} compId
   * @param {Array?} args The arguments that will be passed to the hook callbacks.
   */
  executeHooksAndUpdateValue(RMI, hookName, compType, compId, args) {
    function executeTypeHook(hooks) {
      if (!hooks) { return; }
      const hooksForCompType = hooks[compType] || [];
      const hooksForAllCompType = hooks['*'] || [];
      _.forEach(hooksForCompType.concat(hooksForAllCompType), cb => {
        cb.apply(this, [RMI, hookName, compId].concat(args || []))
      });
    }

    const hook = this._hooks[hookName];
    const allPropsHooks = this._hooks[HOOKS.all];
    executeTypeHook(hook);
    executeTypeHook(allPropsHooks);
  }

  /**
   * Register callback to run the function specified in when executing the hook specified in the hookName parameter according to the hook name
   * @param {String} hookName The name of the hook to registered
   * Hook name can be specific prop or all for all props
   * @param {String?} compType The component type that will use the callback while running the operation according to the hookType. if no comp
   * will fire for all comps for the hooks name.
   * @param {function} callback function to execute.

   */
  registerHook(hookName, compType, callback) {
    this.validateHookName(hookName);
    if (!callback) {
      return;
    }

    let hook = this._hooks[hookName];
    if (!hook) {
      this._hooks[hookName] = hook = {}
    }

    compType = compType || ALL_COMPS_TYPE;
    let hookForComp = hook[compType];
    if (!hookForComp) {
      hook[compType] = hookForComp = [];
    }

    hookForComp.push(callback);
  }

  get HOOKS () {
    return HOOKS
  }

}

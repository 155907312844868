import _ from 'lodash';

import * as dailymotionVideoUtils from './dailymotionVideoUtils';
import * as facebookVideoUtils from './facebookVideoUtils';
import * as vimeoVideoUtils from './vimeoVideoUtils';
import * as youtubeVideoUtils from './youtubeVideoUtils';

// TODO: unite with santa viewer

const VIDEO_UTILS_PROVIDERS = {
  YOUTUBE: youtubeVideoUtils,
  VIMEO: vimeoVideoUtils,
  DAILYMOTION: dailymotionVideoUtils,
  FACEBOOK: facebookVideoUtils
}

function getMethodForType(videoType, methodName) {
  return VIDEO_UTILS_PROVIDERS[videoType.toUpperCase()] && VIDEO_UTILS_PROVIDERS[videoType][methodName]
}



/**
 * @param {{videoType: string, videoId: string}} videoData
 * @returns {string}
 */
export function getVideoUrl(videoData) {
  if (videoData && videoData.videoType && videoData.videoId) {
    const videoUrlGetter = getMethodForType(videoData.videoType, 'getVideoUrl')
    return videoUrlGetter && videoUrlGetter(videoData.videoId) || ''
  }

  return ''
}

/**
 * @param {string} videoUrl
 * @returns {videoData}
 */
export function getVideoDataFromUrl(videoUrl) {
  let videoData
  _.forEach(VIDEO_UTILS_PROVIDERS, function (methods, videoType) {
    const videoIdGetter = getMethodForType(videoType, 'getVideoIdFromUrl')
    const videoId = videoIdGetter && videoIdGetter(videoUrl)
    if (!_.isEmpty(videoId)) {
      videoData = {
        videoType,
        videoId
      }
      return false // break forEach
    }
  })
  return videoData
}

export const videoTypes = {
  YOUTUBE: 'YOUTUBE',
  VIMEO: 'VIMEO',
  DAILYMOTION: 'DAILYMOTION',
  FACEBOOK: 'FACEBOOK'
};

import _ from 'lodash';

const CHARS_TO_ADD = '...';
const NUM_OF_CHARS_TO_KEEP = 3;
const BG_VIDEO_DEFAULTS = {
  opacity: 1,
  autoplay: true,
  loop: true,
  preload: 'auto',
  mute: true
};
const MEDIA_PLAYER_DEFAULTS = {
  opacity: 1,
  preload: 'auto'
};
const TITLE_LENGTH_LIMIT = 100;

function url2uri(url) {
  return url.replace(/^(.*[\/])/, '');
}

function getIdFromUrl(fileUrl) {
  const result = fileUrl.match(/(?:\/|^)([0-9a-fA-F_]+)(?:\/|$)/);
  return result.length > 1 ? result[1] : '';
}


export function fixMediaTitleLength(value, lengthLimit) {
  if (value.length <= lengthLimit) {
    return value;
  }
  const arr = value.split('');
  const numOfCharsToRemove = value.length - lengthLimit + CHARS_TO_ADD.length;
  const isFileTypeSuffix = value.lastIndexOf('.') > value.length - numOfCharsToRemove - NUM_OF_CHARS_TO_KEEP;
  const fileTypeSuffixIndex = isFileTypeSuffix ? value.lastIndexOf('.') : value.length - 1;
  const removeIndex = fileTypeSuffixIndex - numOfCharsToRemove - NUM_OF_CHARS_TO_KEEP;
  arr.splice(removeIndex, numOfCharsToRemove, CHARS_TO_ADD);
  return arr.join('');
}

/**
 * extracts the generated images from the video payload
 * @param fileOutput
 * @returns {[]} images uri
 */
function parseVideoPosters(fileOutput) {
  return _.map(fileOutput.image, function (image) {
    return url2uri(image.url);
  });
}

/**
 * extracts the video qualities from the video payload
 * @param fileOutput
 * @returns {[{width: number, height: number, quality: string, url: string} || {}]} qualities array
 */
function parseVideoQualities(fileOutput) {
  const mp4Videos = _.filter(fileOutput.video, {format: 'mp4'});
  const storyboard = _.head(_.get(fileOutput, 'storyboard'));
  const qualities = _.map(mp4Videos, function (quality) {
    return _.pick(quality, ['width', 'height', 'quality', 'url']);
  });

  if (storyboard) {
    qualities.push({
      quality: 'storyboard',
      width: storyboard.width,
      height: storyboard.height,
      url: storyboard.url
    });
  }

  return qualities;
}

/**
 * extracts the video adaptive formats and url from the video payload
 * @param fileOutput
 * @returns {[{format: string, url: string}] || []} qualities array
 */
function parseAdaptiveUrls(fileOutput) {
  const adaptiveVideo = getObjectValueByKey(fileOutput, 'adaptive_video');
  return _.map(adaptiveVideo, item => ({
    format: item.format,
    url: item.url
  }));
}

/**
 * since media manager return different cases .
 * @param object
 * @param key
 * @returns {*}
 */
function getObjectValueByKey(object, key) {
  return object[key] || object[_.camelCase(key)]
}

/**
 * parse media manager video file response into WixVideo object scheme
 * @param fileInfo - media manager, video file object response (https://docs.google.com/a/wix.com/document/d/1_fqnV0JcTfMHVpqKJIR9jFV-l6YGxv8_7cc3XQ7I2OY)
 * @param info - media manager, folder information
 * @returns {{type: string, title: *, videoId: string, duration: number, posterImageRef: *, generatedPosters: string[], qualities: ({width: number, height: number, quality: string, url: string}|{})[], artist: {name: (*|string), id: (*|string)}, hasAudio: boolean, fps: (string|*)}}
 * https://docs.google.com/a/wix.com/document/d/1BcbazImlny7Y5k4dE5B6n-FD4_NkXAeluefsl8324Js
 */
function parseVideoFileInfo(fileInfo, info) {
  const fileInput = getObjectValueByKey(fileInfo, 'file_input');
  const fileOutput = getObjectValueByKey(fileInfo, 'file_output');
  const videoId = getIdFromUrl(fileInfo.fileUrl || fileInfo.file_name);
  const title = fixMediaTitleLength(fileInfo.title, TITLE_LENGTH_LIMIT);
  const qualities = parseVideoQualities(fileOutput);
  const adaptiveVideo = parseAdaptiveUrls(fileOutput);
  // parse poster - get the first item in data
  const imageData = _.head(fileOutput.image);
  const posterImageRef = {
    type: 'Image',
    width: imageData.width,
    height: imageData.height,
    uri: url2uri(imageData.url),
    description: info.path ? info.path : undefined
  };

  return {
    type: 'WixVideo',
    title,
    videoId,
    duration: +(fileInput.duration / 1000).toFixed(2),
    posterImageRef,
    generatedPosters: parseVideoPosters(fileOutput),
    qualities,
    adaptiveVideo,
    artist: {name: fileInfo.vendor || '', id: fileInfo.reference || ''},
    hasAudio: getObjectValueByKey(_.head(fileOutput.video), 'audio_bitrate') !== -1,
    fps: _.get(_.head(fileOutput.video), 'fps', '').toString()
  };
}

export function getVideoBackgroundObject(fileInfo, info) {
  const mediaObject = parseVideoFileInfo(fileInfo, info);
  return _.assign(mediaObject, BG_VIDEO_DEFAULTS);
}

export function getMediaPlayerVideoObject(fileInfo, info) {
  const mediaObject = parseVideoFileInfo(fileInfo, info);
  return _.assign(mediaObject, MEDIA_PLAYER_DEFAULTS);
}

export function getVideoPosterObject(videoId, uri, width, height) {
  return {
    type: 'WixVideo',
    videoId,
    posterImageRef: {
      type: 'Image',
      uri,
      width,
      height
    }
  };
}

/**
 *
 * @param fileInfo
 * @param info - media manager, folder information
 * @returns {{uri: *, title: *, width: *, height: *}}
 */
export function getImageBackgroundObject(fileInfo, info) {
  return {
    type: 'Image',
    uri: fileInfo.fileName,
    title: fixMediaTitleLength(fileInfo.title, TITLE_LENGTH_LIMIT),
    description: info.path,
    width: fileInfo.width,
    height: fileInfo.height,
    artist: {name: fileInfo.vendor || '', id: fileInfo.reference || ''}
  };
}

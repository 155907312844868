/*eslint no-use-before-define:0*/
/*eslint no-shadow:0*/
import _ from 'lodash';

const CHILDREN = 'children';
const REPEATER_TYPE = 'wysiwyg.viewer.components.Repeater';

export function getAllCompsUnderRoot(model, rootId) {
  const compsUnderRoot = [rootId];
  for (let i = 0; i < compsUnderRoot.length; i++) {
    const children = _.get(model.components, [compsUnderRoot[i], CHILDREN]) || [];
    compsUnderRoot.push.apply(compsUnderRoot, children);
  }
  compsUnderRoot.shift();

  return compsUnderRoot;
}

export function isTemplateComp(model, compId) {
  const getCompParent = compId => _.get(model, ['components', compId, 'parent']);
  const isCompRepeater = compId => _.get(model, ['components', currentParent, 'type']) === REPEATER_TYPE; //eslint-disable-line no-unused-vars
  let currentComp = compId;
  let currentParent = getCompParent(currentComp);
  // search for the parent that is also direct child of a repeater, if exist
  while (currentParent && !isCompRepeater(currentParent)) {
    currentComp = currentParent;
    currentParent = getCompParent(currentComp);
  }
  // if the direct child is in the repeater's children array, then its a template component, otherwise its a displayed only component
  return isCompRepeater(currentParent) && _.includes(_.get(model, ['components', currentParent, 'children']), currentComp);

}

/*eslint consistent-return:0*/
import _ from 'lodash';
import {getAllCompsUnderRoot} from '../modelUtils';

const DELIMITER = '__';
export const SPLITTED_DATA_TYPES = ['data', 'design'];

/* get Array[ids] or Array[{_id:id}] and returns array of id's*/
function getIdsFromItems(items) {
  return _.map(items, function(item) {
    return _.isString(item) ? item : item._id
  });
}

/*get Array[ids] or Array[{_id:id}] and returns format of {item: itemData, index: index in array}*/
function reduceItems(data, ids) {
  const dataFormatted = _.map(data, v => {
    if (_.isString(v)) {
      return {_id: v};
    }
    return v;

  });
  return _.reduce(dataFormatted, function(result, item, index) {
    if (_.includes(ids, item._id)) {
      result.push({item, index});
    }
    return result;
  }, []);
}

/*return added repeater items - check what newly ids were added
 * return {item: itemData, index: index in array} */
export function getAddedItems(currentData, newData) {
  const newItemsIds = getIdsFromItems(newData);
  const currentDataIds = getIdsFromItems(currentData);
  const newlyAddedItemsIds = _.difference(newItemsIds, currentDataIds);
  return reduceItems(newData, newlyAddedItemsIds);
}

/*return removed repeater items - check what ids were removed
 * return {item: itemData, index: index in array} */
export function getRemovedItems(currentData, newData) {
  const newItemsIds = getIdsFromItems(newData);
  const currentDataIds = getIdsFromItems(currentData);
  const removedIds = _.difference(currentDataIds, newItemsIds);
  return reduceItems(currentData, removedIds);
}

export function getUniqueDisplayedId(originalId, itemId) {
  return originalId + DELIMITER + itemId;
}

export function getOriginalId(uniqueItemId) {
  if (!uniqueItemId) {
    return;
  }
  return uniqueItemId.split(DELIMITER)[0];
}

export function getItemId(uniqueItemId) {
  if (!uniqueItemId) {
    return;
  }
  return uniqueItemId.split(DELIMITER)[1];
}

export function getTemplateCompIds(RMI, repeaterId, itemsData) {
  const templateCompIds = getAllCompsUnderRoot(_.get(RMI, '_model'), repeaterId);
  const itemsId = _.compact(_.map(itemsData, itemData => _.get(itemData, 'item._id')));
  return _.reduce(itemsId, (result, itemId) => result.concat(_.map(templateCompIds, templateCompId => getUniqueDisplayedId(templateCompId, itemId))), []);
}

import _ from 'lodash';

const IMAGE_URI = 'image://';
const DOC_URI = 'wix:document://';
const VERSION = 'v1';
const wixCodeImgUriTemplate = _.template(IMAGE_URI + VERSION + '/${uri}/${width}_${height}/${title}');
const wixCodeDocUriTemplate = _.template(DOC_URI + VERSION + '/${uri}/${filename}');
const MEDIA_SRC_REGEX = /^image:\/\/(.*)\/(.*)\/(.*)_(.*)\/(.*)/;

export function generateWixCodeImageUri(imageData) {
  return wixCodeImgUriTemplate(imageData);
}

export function generateWixCodeDocumentUri(documentData) {
  return wixCodeDocUriTemplate(documentData);
}

function removeEmptyValues(res, v, k) {
  if (v) {
    res[k] = v;
  }
  return res;
}

export function extractImageRelativeUriFromWixUri(wixCodeImageUri) {
  if (MEDIA_SRC_REGEX.test(wixCodeImageUri)) {
    const [, , baseSrc] = wixCodeImageUri.match(MEDIA_SRC_REGEX) || [0, 0, wixCodeImageUri];
    return baseSrc;
  }

  return wixCodeImageUri;
}

export function extractImageDataFromWixUri(wixCodeImageUri) {
  if (MEDIA_SRC_REGEX.test(wixCodeImageUri)) {
    const [, , uri, width, height, title] = wixCodeImageUri.match(MEDIA_SRC_REGEX) || [0, 0, wixCodeImageUri];
    return _.reduce({uri, width, height, title}, removeEmptyValues, {});
  }

  return {uri: wixCodeImageUri};
}

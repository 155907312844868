export {RemoteGlobalsInterface} from './RemoteGlobalsInterface';
export {RemoteModelInterface} from './RemoteModelInterface';
import * as backgroundUtils from './backgroundUtils';
import * as componentsHooks from './RMIHooks/componentsHooks';
import * as linkUtils from './linkUtils';
import * as richTextUtils from './richTextUtils';
import * as svgUtils from './svgUtils';
import * as typeData from './typeUtils/typeData';
import * as uriUtils from './uriUtils';
import * as videoUrlsUtils from './videoUrlsUtils/videoUrlsUtils';
import * as widgetUtils from './widgetUtils';
import {TypeUtils} from './typeUtils/typeUtils';
import repeaterUtils from './repeaterUtils/repeaterUtils';

export const typeUtils = new TypeUtils(typeData)
export {
  linkUtils,
  widgetUtils,
  uriUtils,
  richTextUtils,
  videoUrlsUtils,
  repeaterUtils,
  backgroundUtils,
  svgUtils,
  componentsHooks
}

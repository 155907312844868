import _ from 'lodash';

const externalRegx = /^(http|https):\/\/(.*)/;
const mailtoRegx = /^mailto:([^\?]*)(?:\?subject=(.*)?)?/;
const pageRegx = /^\/([^ \?]*)[\?]?(.*)/;
const pageTopRegx = /^(\/?)([^ \?]*)#(top|SCROLL_TO_TOP)[\?]?(.*)/;
const pageBottomRegx = /^(\/?)([^ \?]*)#(bottom|SCROLL_TO_BOTTOM)[\?]?(.*)/;
const oldDocumentFormatRegx = /^document:\/\/(.*)/;
const newDocumentFormatRegx = /^wix:document:\/\/v1\/(.+)\/(.+)/;
const phoneRegx = /^tel:(.*)/;
const pageWithAnchor = /^\/([^ \?]*)#([^ \?]*)[\?]?(.*)/;
const samePageWithAnchor = /^#([^ \?]*)[\?]?(.*)/;
const ANCHOR_TYPE_TO_NAME = {
  SCROLL_TO_TOP: 'top',
  SCROLL_TO_BOTTOM: 'bottom'
};
const LinkType = {
  PAGE: 'PageLink',
  DYNAMIC_PAGE: 'DynamicPageLink',
  DYNAMIC_PAGE_WITH_QUERY_PARAMS: 'DynamicPageLinkWithQueryParams',
  EXTERNAL: 'ExternalLink',
  EMAIL: 'EmailLink',
  DOCUMENT: 'DocumentLink',
  PHONE: 'PhoneLink',
  PAGE_TOP: 'AnchorLink',
  PAGE_BOTTOM: 'AnchorLink',
  SAME_PAGE_ANCHOR_LINK: 'SamePageAnchorLink',
  ANCHOR: 'AnchorLink'
};

const SUPPORTED_TARGETS = ['_blank', '_self'];

const MASTER_PAGE_ID = 'masterPage';

const anchorLinkBaseTemplate = _.template('<%=linkObject.pageId%>#<%=linkObject.anchorDataId%>', {variable: 'linkObject'});
const pageLinkBaseTemplate = _.template('/<%=linkObject.pageId%>', {variable: 'linkObject'});

class UnsupportedLinkTypeError extends Error {
  constructor() {
    super('Unsupported link type');
    this.name = 'UnsupportedLinkTypeError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnsupportedLinkTypeError);
    }
  }
}

class UnsupportedTargetValueError extends Error {
  constructor() {
    super('Unsupported target value');
    this.name = 'UnsupportedTargetValueError';
    this.supportedTargets = SUPPORTED_TARGETS;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnsupportedTargetValueError);
    }
  }
}

function getInnerRoute(parts, hasAnchor, hasQueryParams) {
  let partsWithoutQueryParams = parts;
  if (hasQueryParams) {
    partsWithoutQueryParams = _.dropRight(parts)
  }
  const partsWithoutPrefixAndSuffix = _.drop(hasAnchor ? _.dropRight(partsWithoutQueryParams) : partsWithoutQueryParams);
  return _.join(partsWithoutPrefixAndSuffix, '/');
}

function getPageParts(str) {
  const parts = _.split(str, '/');
  if (parts.length > 1) {
    const innerRoute = _.join(_.drop(parts), '/');
    return _.concat([], _.head(parts), innerRoute);
  }
  return parts;
}

const LINK_URL_TEMPLATES = {
  PageLink: {
    url: getPageLinkTemplate, link(type, parts, siteStructurePrefixes, siteStructurePages) {
      const pageParts = getPageParts(parts[1]);
      const pageLinkObj = {
        type,
        pageId: '#' + translateUriSeoToPageId(_.head(pageParts), siteStructurePages)

      };
      if (parts.length === 3 && parts[2]) {
        _.assign(pageLinkObj, {queryParams: convertQueryParamsToObject(parts[2]) || ''})
      }
      const tpaInnerRoute = pageParts[1];
      if (tpaInnerRoute) {
        _.assign(pageLinkObj, {tpaInnerRoute})
      }
      return pageLinkObj;
    }, regex: [pageRegx]
  },
  DocumentLink: {
    url: _.template('wix:document://v1/<%=linkObject.docId%>/<%=linkObject.name%>', {variable: 'linkObject'}), link(type, parts) {
      return {
        type,
        docId: parts[1],
        name: _.isUndefined(parts[2]) ? '' : parts[2]
      };
    }, regex: [oldDocumentFormatRegx, newDocumentFormatRegx]
  },
  PhoneLink: {
    url: _.template('tel:<%=linkObject.phoneNumber%>', {variable: 'linkObject'}), link(type, parts) {
      return {
        type,
        phoneNumber: parts[1]
      };
    }, regex: [phoneRegx]
  },
  EmailLink: {
    url: getEmailLinkTemplate,
    link(type, parts) {
      const data = {
        type,
        recipient: _.escape(parts[1])
      };
      if (parts[2]) {
        data.subject = _.escape(parts[2]);
      }
      return data;
    },
    regex: [mailtoRegx]
  },
  ExternalLink: {
    url: _.template('<%=linkObject.url%>', {variable: 'linkObject'}), link(type, parts, siteStructurePrefixes, siteStructurePages, target) {
      if (target && !_.includes(SUPPORTED_TARGETS, target)) {
        throw new UnsupportedTargetValueError();
      }
      return {
        type,
        url: _.template('<%= partA %>://<%= partB%>')({partA: parts[1], partB: parts[2]}),
        target: target || '_blank'
      };
    }, regex: [externalRegx]
  },
  DynamicPageLink: {
    url: getDynamicPageLinkTemplate, link(type, parts, siteStructurePrefixes) {
      const hasAnchor = _.startsWith(_.last(parts), '#');
      const routerData = _.find(siteStructurePrefixes, {name: parts[0]});
      const data = {
        type,
        innerRoute: getInnerRoute(parts, hasAnchor) || '/',
        routerId: routerData.id
      };
      if (hasAnchor) {
        const anchorName = _.last(parts).replace('#', '');
        data.anchorDataId = _.get(_.invert(ANCHOR_TYPE_TO_NAME), anchorName, anchorName);
      }
      return data;
    }, regex: [pageTopRegx, pageBottomRegx, pageWithAnchor, pageRegx]
  },
  DynamicPageLinkWithQueryParams: {
    url: getDynamicPageLinkTemplate, link(type, parts, siteStructurePrefixes) {
      const queryParams = _.last(parts);
      const hasAnchor = _.startsWith(parts[parts.length - 2], '#');
      const routerData = _.find(siteStructurePrefixes, {name: parts[0]});
      const data = {
        type: LinkType.DYNAMIC_PAGE,
        innerRoute: getInnerRoute(parts, hasAnchor, true) || '/',
        routerId: routerData.id,
        queryParams: convertQueryParamsToObject(queryParams)

      };
      if (hasAnchor) {
        const anchorName = parts[parts.length - 2].replace('#', '');
        data.anchorDataId = _.get(_.invert(ANCHOR_TYPE_TO_NAME), anchorName, anchorName);
      }
      return data;
    }, regex: [pageTopRegx, pageBottomRegx, pageWithAnchor, pageRegx]
  },
  AnchorLink: {
    url: getAnchorLinkTemplate, link(type, parts, siteStructurePrefixes, pages) {
      const anchorDataId = parts[2];
      const anchorLinkObj = {
        type,
        pageId: '#' + translateUriSeoToPageId(parts[1], pages),
        anchorDataId: _.invert(ANCHOR_TYPE_TO_NAME)[anchorDataId] || anchorDataId
      };
      if (parts.length === 4 && parts[3]) {
        _.assign(anchorLinkObj, {queryParams: convertQueryParamsToObject(parts[3])})
      }
      return anchorLinkObj
    }, regex: [pageWithAnchor]
  },
  SamePageAnchorLink: {
    url: getAnchorLinkTemplate, link(type, parts) {
      const anchorDataId = parts[1];
      const samePageAnchorLinkObj = {
        type: LinkType.ANCHOR,
        anchorDataId: _.invert(ANCHOR_TYPE_TO_NAME)[anchorDataId] || anchorDataId,
        pageId: '#' + MASTER_PAGE_ID
      };
      if (parts.length === 3 && parts[2]) {
        _.assign(samePageAnchorLinkObj, {queryParams: convertQueryParamsToObject(parts[2])})
      }
      return samePageAnchorLinkObj
    }, regex: [samePageWithAnchor]
  }
};

function getUrlParts(regExpArr, value, type) {
  if (type === LinkType.DYNAMIC_PAGE || type === LinkType.DYNAMIC_PAGE_WITH_QUERY_PARAMS) {
    return getDynamicPageParts(value);
  }
  const regexp = _.find(regExpArr, function (regExpItem) {
    return regExpItem.test(value);
  });
  let parts = value.match(regexp);
  parts = parts ? parts : [];
  return parts;
}

function getDynamicPageParts(str) {
  const pageRegRes = str.match(pageRegx);
  const parts = pageRegRes[1].replace('#', '/#').split(/[\/]+/);
  const hasQueryParam = _.includes(str, '?');
  if (hasQueryParam) {
    parts.push(_.last(pageRegRes))
  }
  return parts;
}

function getAnchorName(anchorDataId) {
  return ANCHOR_TYPE_TO_NAME[anchorDataId] || anchorDataId;
}

function getDynamicPagePrefix(siteStructurePrefixes, linkObject) {
  const routerConfig = _.find(siteStructurePrefixes, {id: linkObject.routerId});
  return routerConfig && routerConfig.prefix;
}

function getDynamicPageLinkTemplate(linkObject, siteStructurePrefixes) {
  const dynamicPageSuffix = !linkObject.innerRoute || linkObject.innerRoute === '/' ? '' : '/' + linkObject.innerRoute;
  const route = getDynamicPagePrefix(siteStructurePrefixes, linkObject) + dynamicPageSuffix;

  const anchorDataId = getAnchorName(linkObject.anchorDataId);
  const customLinkObject = {
    pageId: _.startsWith(route, '/') ? route : '/' + route,
    anchorDataId
  };
  return customLinkObject.anchorDataId ? anchorLinkBaseTemplate(customLinkObject) : route;
}

function getPageLinkTemplate(linkObject, siteStructurePrefixes, siteStructurePages) {
  const pageId = getPageIdForURL(linkObject.pageId);
  const pageObj = _.find(siteStructurePages, {id: pageId});
  return pageObj ? pageObj.url : pageLinkBaseTemplate({pageId});
}

function getEmailLinkTemplate(linkObject) {
  let templateString = 'mailto:<%=linkObject.recipient%>';
  if (_.has(linkObject, 'subject')) {
    templateString += '?subject=<%=linkObject.subject%>';
  }

  return _.template(templateString, {variable: 'linkObject'})(linkObject);
}

function getAnchorLinkTemplate(linkObject, siteStructurePrefixes, siteStructurePages) {
  const anchorName = getAnchorName(linkObject.anchorDataId);
  if (isMasterPage(extractPageId(linkObject.pageId))) {
    return '#' + anchorName;
  }

  const pageId = getPageIdForURL(linkObject.pageId);
  const pageObj = _.find(siteStructurePages, {id: pageId});
  return pageObj ? anchorLinkBaseTemplate({
    pageId: pageObj.url,
    anchorDataId: anchorName
  }) : anchorLinkBaseTemplate({
    pageId: _.startsWith(pageId, '/') ? pageId : '/' + pageId,
    anchorDataId: anchorName
  });
}

function getPageIdForURL(pageId) {
  pageId = extractPageId(pageId);
  if (isMasterPage(pageId)) {
    return '';
  }

  return pageId.replace('#', '');
}

function extractPageId(pageId) {
  if (!pageId) {
    return pageId;
  }

  if (_.isPlainObject(pageId)) {
    if (pageId.pageUriSEO) {
      return pageId.pageUriSEO;
    } else if (isMasterPage(pageId.id)) {
      return MASTER_PAGE_ID;
    }

    throw new Error('pageId object should contain pageUriSEO');
  }

  return pageId;
}

function translateUriSeoToPageId(uriSeo, siteStructurePages) {
  const isHomePage = uriSeo === '';
  const pageData = isHomePage ? _.find(siteStructurePages, 'isMainPage') : _.find(siteStructurePages, {url: '/' + uriSeo});
  return _.get(pageData, 'id', uriSeo);
}

function isDynamicPage(urlParts, siteStructurePrefixes) {
  return _.find(siteStructurePrefixes, {name: urlParts[0]});
}

function getDynamicPageType(str) {
  return _.includes(str, '?') ? LinkType.DYNAMIC_PAGE_WITH_QUERY_PARAMS : LinkType.DYNAMIC_PAGE;
}

function isMasterPage(pageId) {
  return pageId.replace('#', '') === MASTER_PAGE_ID;
}

function getLinkType(str, siteStructurePrefixes) {
  switch (true) {
  case externalRegx.test(str):
    return LinkType.EXTERNAL;
  case mailtoRegx.test(str):
    return LinkType.EMAIL;
  case samePageWithAnchor.test(str):
    return LinkType.SAME_PAGE_ANCHOR_LINK;
  case pageTopRegx.test(str):
    return isDynamicPage(getDynamicPageParts(str), siteStructurePrefixes) ? getDynamicPageType(str) : LinkType.PAGE_TOP;
  case pageBottomRegx.test(str):
    return isDynamicPage(getDynamicPageParts(str), siteStructurePrefixes) ? getDynamicPageType(str) : LinkType.PAGE_BOTTOM;
  case pageWithAnchor.test(str):
    return isDynamicPage(getDynamicPageParts(str), siteStructurePrefixes) ? getDynamicPageType(str) : LinkType.ANCHOR;
  case pageRegx.test(str):
    return isDynamicPage(getDynamicPageParts(str), siteStructurePrefixes) ? getDynamicPageType(str) : LinkType.PAGE;
  case oldDocumentFormatRegx.test(str):
    return LinkType.DOCUMENT;
  case newDocumentFormatRegx.test(str):
    return LinkType.DOCUMENT;
  case phoneRegx.test(str):
    return LinkType.PHONE;
  default:
    throw new UnsupportedLinkTypeError();
  }
}

export function convertLinkObjectToUrl(siteStructurePrefixes, linkObject, siteStructurePages) {
  if (!linkObject || !_.get(LINK_URL_TEMPLATES, [linkObject.type, 'url'])) {
    throw new Error('Provided link type is not supported');
  }
  const urlTemplate = _.get(LINK_URL_TEMPLATES, [linkObject.type, 'url']);
  return urlTemplate(linkObject, siteStructurePrefixes, siteStructurePages);
}

export function convertUrlToLinkObject(siteStructurePrefixes, url, target, siteStructurePages) {
  const type = getLinkType(url, siteStructurePrefixes);
  const urlParts = getUrlParts(LINK_URL_TEMPLATES[type].regex, url, type);
  const linkTemplate = _.get(LINK_URL_TEMPLATES, [type, 'link']);
  return linkTemplate(type, urlParts, siteStructurePrefixes, siteStructurePages, target);
}

export function getAnchorDataId(linkItem) {
  return getAnchorName(linkItem.anchorDataId);
}

export function getPageId(linkItem) {
  return extractPageId(linkItem.pageId);
}

export function convertQueryParamsToObject(query) {
  if (!_.isString(query)) {
    return {};
  }
  const queryStringArray = query.split('&');
  return _.reduce(queryStringArray, function (res, queryParam) {
    const splitedQueryParam = queryParam.split('=');
    res[splitedQueryParam[0]] = splitedQueryParam[1] ? decodeURIComponent(splitedQueryParam[1]) : null;
    return res;
  }, {});
}

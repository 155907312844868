import _ from 'lodash';
import * as modelUtils from '../../modelUtils';
import repeaterUtils from '../../repeaterUtils/repeaterUtils';

function cloneConnections(model, templateCompIds, itemIds) {
  const connections = _.get(model, 'connections');
  const contexts = _.keys(connections);
  _.forEach(contexts, context => {

    const roles = _.keys(_.get(connections, context));
    _.forEach(roles, role => {

      const compIds = _.keys(_.get(connections, [context, role]));
      _.forEach(compIds, compId => {
        if (_.includes(templateCompIds, compId)) {
          const templateConfig = _.get(connections, [context, role, compId]);

          _.forEach(itemIds, itemId => {
            const displayedItemId = repeaterUtils.structure.getUniqueDisplayedId(compId, itemId);
            _.set(connections, [context, role, displayedItemId], _.cloneDeep(templateConfig));
          })
        }
      });
    })
  });
}

function updateCompsScope(model, templateCompIds, itemIds) {
  _.forEach(templateCompIds, templateCompId => {
    _.forEach(itemIds, itemId => {
      const displayedItemId = repeaterUtils.structure.getUniqueDisplayedId(templateCompId, itemId);
      model.componentsScopes[displayedItemId] = null;
    });
  });
}

function cloneComponents(model, templateCompIds, itemIds, repeaterId) {
  _.forEach(templateCompIds, templateCompId => {
    _.forEach(itemIds, itemId => {
      const newDisplayedCompId = repeaterUtils.structure.getUniqueDisplayedId(templateCompId, itemId);
      const templateData = model.components[templateCompId];
      const newDisplayedCompData = _.cloneDeep(templateData);
      newDisplayedCompData.displayedOnlyComponents = [];
      newDisplayedCompData.children = _.map(templateData.children, childId => repeaterUtils.structure.getUniqueDisplayedId(childId, itemId));
      newDisplayedCompData.parent = templateData.parent === repeaterId ? repeaterId : repeaterUtils.structure.getUniqueDisplayedId(templateData.parent, itemId);
      newDisplayedCompData.displayedRoot = repeaterId;
      newDisplayedCompData.isDisplayed = !!_.get(model.components[repeaterId], 'isDisplayed');
      /*change data-item id for refs*/
      _.forEach(repeaterUtils.structure.SPLITTED_DATA_TYPES, dataType => {
        const compDataTypeValue = newDisplayedCompData[dataType];
        if (compDataTypeValue.id) {
          compDataTypeValue.id = repeaterUtils.structure.getUniqueDisplayedId(newDisplayedCompData[dataType].id, itemId);
          _.forEach(compDataTypeValue, (val, key) => {
            if (_.isObject(val) && val.id) {
              compDataTypeValue[key].id = repeaterUtils.structure.getUniqueDisplayedId(compDataTypeValue[key].id, itemId);
            }
          });
        }
      });

      model.components[newDisplayedCompId] = newDisplayedCompData;
    });
  });
}

function cloneComponentsAndConnections(model, templateCompIds, itemIds, repeaterId) {
  cloneConnections(model, templateCompIds, itemIds);
  cloneComponents(model, templateCompIds, itemIds, repeaterId);
  updateCompsScope(model, templateCompIds, itemIds);
}

function removeConnections(model, templateCompIds, itemIds) {
  const connections = _.get(model, 'connections');
  const contexts = _.keys(connections);

  _.forEach(contexts, context => {
    const roles = _.keys(_.get(connections, [context]));

    _.forEach(roles, role => {
      const compIds = _.keys(_.get(connections, [context, role]));

      _.forEach(compIds, compId => {
        const compItemId = repeaterUtils.structure.getItemId(compId);
        const compOriginalId = repeaterUtils.structure.getOriginalId(compId);

        if (compItemId && _.includes(itemIds, compItemId) && _.includes(templateCompIds, compOriginalId)) {
          delete connections[context][role][compId];
        }
      });
    });
  });
}

function removeCompsScope(model, templateCompIds, itemIds) {
  _.forEach(templateCompIds, templateCompId => {
    _.forEach(itemIds, itemId => {
      const displayedItemId = repeaterUtils.structure.getUniqueDisplayedId(templateCompId, itemId);
      delete model.componentsScopes[displayedItemId];
    });
  });
}

function removeComponents(model, templateCompIds, itemIds) {
  _.forEach(templateCompIds, templateCompId => {
    _.forEach(itemIds, itemId => {
      const displayedItemId = repeaterUtils.structure.getUniqueDisplayedId(templateCompId, itemId);
      delete model.components[displayedItemId];
    });
  });
}

function removeComponentsAndConnections(model, templateCompIds, itemIds) {
  removeConnections(model, templateCompIds, itemIds);
  removeCompsScope(model, templateCompIds, itemIds);
  removeComponents(model, templateCompIds, itemIds)
}

/*on repeater set items - creates displayed components and connections for added items and removes components and connections that are no loger in items*/
export function updateRepeaterItems(RMI, hookName, repeaterId, partial) {
  if (partial.items) {
    const templateCompIds = modelUtils.getAllCompsUnderRoot(RMI._model, repeaterId);
    const repeaterData = _.get(RMI._model.components, [repeaterId, 'data']);
    const addedItems = repeaterUtils.items.getAddedItems(repeaterData.items, partial.items);
    cloneComponentsAndConnections(RMI._model, templateCompIds, _.map(addedItems, 'item._id'), repeaterId);

    const removedItems = repeaterUtils.items.getRemovedItems(repeaterData.items, partial.items);
    removeComponentsAndConnections(RMI._model, templateCompIds, _.map(removedItems, 'item._id'));
    _.forEach(templateCompIds, templateCompId => {
      const templateCompData = RMI._model.components[templateCompId];
      templateCompData.displayedOnlyComponents = _.map(partial.items, itemId => repeaterUtils.structure.getUniqueDisplayedId(templateCompId, itemId));
    });
  }
}

/*Hook triggered on each set and check if the set is for template sync all existing item instances*/
export function syncRepeaterItemData(RMI, hookName, compId) {
  const model = RMI._model;
  // set comp property to the template comp, since its isDisplayed property is always false
  if (hookName !== 'registerEvent' && modelUtils.isTemplateComp(model, compId)) {
    const comp = _.get(model, ['components', compId]);
    const partial = arguments[3];
    model.components[compId][hookName] = _.defaults({}, partial, comp[hookName]);
  }

  const displayedOnlyComponents = _.get(RMI._model.components, [compId, 'displayedOnlyComponents']);
  if (!_.isEmpty(displayedOnlyComponents)) { //is repeater template with displayed only comps
    let funcName = `set${_.startCase(hookName)}`;
    if (hookName === 'registerEvent') {
      funcName = 'registerEvent';
      _.forEach(displayedOnlyComponents, itemId => {
        const args = [...arguments];
        RMI[funcName].apply(RMI, [args[3], itemId].concat(args.splice(4)))
      });
      return;
    }
    _.forEach(displayedOnlyComponents, itemId => {
      const args = [...arguments];
      if (hookName === 'data' && !_.isFunction(_.last(args))) { //if there is no callback should call setDataWithoutUpdate and not setData
        RMI.setDataWithoutUpdate.apply(RMI, [itemId].concat(args.splice(3)))
        return;
      }
      RMI[funcName].apply(RMI, [itemId].concat(args.splice(3)))
    });
  }
}

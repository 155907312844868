import _ from 'lodash';

export class RemoteGlobalsInterface {
  constructor(modelJson) {
    this._globals = modelJson || {
      navigation: {},
      sessionInfo: {},
      appsData: {},
      siteMemberData: {uid: null, permissions: null, onUserLogin: []}
    };
  }

  addNavigation(navigationData) {
    this._globals.navigation = navigationData;
  }

  addSiteMemberData(siteMemberData) {
    const shouldTriggerOnLoginCallback = siteMemberData.uid && siteMemberData.uid !== this._globals.siteMemberData.uid;
    _.assign(this._globals.siteMemberData, siteMemberData);
    if (shouldTriggerOnLoginCallback) {
      const onUserLoginCallbacks = this._globals.siteMemberData.onUserLogin;
      _.forEach(onUserLoginCallbacks, callback => {
        callback(siteMemberData);
      });
    }
  }

  addAppsData(appsData) {
    this._globals.appsData = appsData
  }

  getAppsData() {
    return _.cloneDeep(this._globals.appsData);
  }

  addOnUserLoginCallback(callback) {
    this._globals.siteMemberData.onUserLogin.push(callback);
  }

  addSessionInfoProp(addSessionInfoProp) {
    _.assign(this._globals.sessionInfo, addSessionInfoProp);
  }

  //Getters

  getAppData(appDefinitionId) {
    return _.cloneDeep(_.get(this._globals.appsData, appDefinitionId));
  }

  getNavigationInfo() {
    return _.cloneDeep(this._globals.navigation);
  }

  getSiteMemberData(appDefinitionId) {
    const siteMemberData = _.cloneDeep(this._globals.siteMemberData);
    if (appDefinitionId) {
      const appData = _.get(this._globals.appsData, appDefinitionId);
      if (appData) {
        _.assign(siteMemberData, _.pick(appData, ['instance', 'instanceId']))
      }
    }
    return siteMemberData;
  }

  getSessionInfo() {
    return _.cloneDeep(this._globals.sessionInfo);
  }

  toJson() {
    return this._globals;
  }
}
